export const environment = {
    production: true,
    isLangugaeSelectable: true,
    backendOrderUrl: 'https://order.multiverzumkiado.hu',
    backendStoreUrl: 'https://store.multiverzumkiado.hu',
    backendEmailUrl: 'https://email.multiverzumkiado.hu',
    backendAuthUrl: 'https://auth.multiverzumkiado.hu',
    backendFileManagerUrl: 'https://file.multiverzumkiado.hu',
    backendConfiguratorUrl : 'https://configurator.multiverzumkiado.hu',
    apiUrl: 'https://multiverzumkiado.hu/',
    downloadUrl:'https://file.multiverzumkiado.hu/file/downloadFile'


};

