<div class="products">
    <div class="title">
        <div class="tit">
            <h3>TERMÉKEK</h3>
            <div class="add-icon">
                <mat-icon routerLink="/addproduct">add_circle</mat-icon>
            </div>
        </div>
    </div>

    <div class="add-new-product">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="listFilter" type="search" placeholder="Szerző, cím neve" />
                <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div>
            </div>
        </div>
        <!-- <button routerLink="/addproduct">ÚJ TERMÉK HOZZÁADÁSA</button> -->
    </div>
    <div class="productss">
        <div class="product-c">
            <div *ngFor="let product of filteredBooks; let i = index" class="product">
                <div class="pro">
                    <div class="author">
                        <div
                            [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
                            class="button"
                            routerLink="{{ product.routerLink }}"
                        >
                            {{ product.author }} : {{ product.title }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
