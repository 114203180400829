import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, ToastmessageService } from '@prekog/pw-common';
import { OrderWithDate } from './orderwithdate';
import { GetOrdersResponse } from '../get-orders-response.model';
import { CustomerOrderModel } from '../customer-order-modell';
import { GetOrderDetailsByIdRequest } from '../getorderdetailsbyorderid/getorderdetailsbyidrequest';
import { GetOrderDetailsByIdResponse } from '../getorderdetailsbyorderid/getorderdetailsbyidresponse';
import { OrderDetails } from '../order.model';
import { OrderProduct } from '../order-product.model';
import { GetOrderedStoreProductsResponse } from '../getorderedstoreproductsresponse';
import { GetOrderedStoreProductsRequest } from '../getorderedstoreproductsrequest';
import { StoreOrderedProduct } from '../storeorderproduct';
import { Product } from '../product.model';
import { StatusOption } from '../statusoption';
import { GetProductRequest } from '../get-product-request';
import { ExportToExcelRequest } from '../export-to-excel-request';
import { OrderExportToExcel } from '../order-export-to-excel';
import { SafeResourceUrl } from '@angular/platform-browser';
import { OrderWithStatusOption } from '../order-with-statusoption';
import { GetOrderReferenceRequest } from '../getorderreferencerequest';
import { GetOrderReferenceResponse } from '../getorderreferenceresponse';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmStatusPopupComponent } from 'src/app/confirm-status-popup/confirm-status-popup.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'app-getorders',
    templateUrl: './getorders.component.html',
    styleUrls: ['./getorders.component.scss']
})
export class GetordersComponent implements OnInit {
    orderBackendUrl = '';

    private _listFilter = '';

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };

    filteredOrder: OrderWithDate[] = [];

    orders: OrderWithDate[] = [];

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredOrder = this.performFilter(value);
    }

    getOrdersUrl = '/admin/get-orderswith';

    constructor(
        private _router: Router,
        private _apiRequestService: ApiRequestService,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        private _toastMessageService: ToastmessageService
    ) {
        this.orderBackendUrl = _orderBackendUrl;
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService
                .sendRequest<{}, GetOrdersResponse>(this.orderBackendUrl, true, {}, true, this.getOrdersUrl)
                .subscribe((response) => {
                    this.orders = response.orderList as OrderWithDate[];
                    console.log(this.orders);

                    this.filteredOrder = this.orders;
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    performFilter(filterBy: string): OrderWithDate[] {
        filterBy = filterBy.toLocaleLowerCase();

        return this.orders.filter((order: OrderWithDate) => order.orderReference?.toLocaleLowerCase().includes(filterBy));
    }
}
