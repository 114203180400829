import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BannerConfig } from '../orderbanners/bannerconfig';
import { HttpError, ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UploadFileResponse } from 'src/app/products/addproduct/uploadfileresponse';
import { AddBannerRequest } from './addbannerrequest';
import { AddBannerResponse } from './addbannerresponse';

@Component({
    selector: 'app-addbanner',
    templateUrl: './addbanner.component.html',
    styleUrls: ['./addbanner.component.scss']
})
export class AddbannerComponent implements OnInit {
    bannerForm?: FormGroup;

    addCoverFile = false;

    bannerConfig: BannerConfig = {};

    configuratorBaseUrl = '';

    @ViewChild('fileCoverInput') fileCoverInput!: ElementRef;
    fileCover: File | null = null;

    addBannerUrl = '/admin/add-banner';

    url = '';

    maxFileSize = 5000000;

    uploadFileUrl = '/admin/uploadFile';

    fileBaseUrl = '';

    downloadUri = '';

    constructor(
        private _router: Router,
        private _toastMessageService: ToastmessageService,
        private _fb: FormBuilder,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        private _translateService: TranslationService
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
        this.fileBaseUrl = _fileManagerUrl;
        this.createBannerForm();
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
    }

    addBanner(banner: BannerConfig) {
        if (!this.addCoverFile) {
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem válassza ki a képet!', MAT_ICON.warning);
            this.dismissError();
        }
        else if (this.fileCover?.size! > this.maxFileSize) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
        } else {
            this._apiRequestService
                .uploadFile(this.fileCover!, true, true, this.fileBaseUrl, this.uploadFileUrl)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;
                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();
                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    const uploadFileResponse = response as UploadFileResponse;
                    this.downloadUri = uploadFileResponse.downloadUri as string;
                    this.collectBannerData(this.downloadUri);
                    const addBannerRequest: AddBannerRequest = {
                        bannerConfig: this.bannerConfig
                    };
                    this.addBannerObject(addBannerRequest);
                });
        }
    }

    addBannerObject(addBannerRequest: AddBannerRequest) {
        this._apiRequestService
            .sendRequest<AddBannerRequest, AddBannerResponse>(this.configuratorBaseUrl, true, addBannerRequest, true, this.addBannerUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this._router.navigateByUrl('/banners');
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    onChange(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCover = target.files![0];
        if (this.fileCover.name.split('.').pop() === 'jpg') {
            this.addCoverFile = true;
        }
        else {
            this.addCoverFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".jpg" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    clearSelectedCoverFile() {
        this.fileCoverInput.nativeElement.value = null;
        this.addCoverFile = false;
    }

    createBannerForm() {
        this.bannerForm = this._fb.group({
            title: ['', [Validators.required]],
            link: ['', [Validators.required]]
        });
    }

    collectBannerData(downloadUri: string) {
        this.bannerConfig = {
            title: this.bannerForm?.controls['title'].value as string | undefined,
            link: this.bannerForm?.controls['link'].value as string | undefined,
            orderNumber: 1
        };

        this.bannerConfig.imageUrl = downloadUri;
    }
}
