<div class="products">
    <div class="title">
        <div class="tit">
            <h3>RENDELÉSEK</h3>
            <div class="add-icon">
                <button routerLink="/order">Összes rendelési tétel</button>
            </div>
        </div>
    </div>

    <div class="add-new-product">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="listFilter" type="search" placeholder="Rendelési azonosító" />
                <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="productss">
        <div class="titles">
            <div class="pro">
                <div class="author">
                    <div [ngStyle]="{ background: '#2C3333', color: 'white' }" class="datas">
                        <div class="data">
                            <span>DÁTUM</span>
                        </div>
                        <div class="data">
                            <span>RENDELÉSI AZONOSÍTÓ</span>
                        </div>
                        <div class="data">
                            <span>NÉV</span>
                        </div>
                        <div class="data">
                            <span>EMAIL CÍM</span>
                        </div>
                        <div class="data">
                            <span>ÖSSZEVÁRJA?</span>
                        </div>
                        <div class="data">
                            <span>VÉGÖSSZEG</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="products-c">
            <div *ngFor="let order of filteredOrder; let i = index" class="product">
                <div class="pro">
                    <div class="author">
                        <div
                            routerLink="{{ order.id }}"
                            [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
                            class="datas"
                        >
                            <div class="data">
                                <span>{{ order.orderDate | date }}</span>
                            </div>
                            <div class="data">
                                <span [ngStyle]="{ 'font-weight': 'bold' }">{{ order.orderReference }}</span>
                            </div>
                            <div class="data">
                                <span>{{ order.name }}</span>
                            </div>
                            <div class="data">
                                <span>{{ order.email }}</span>
                            </div>
                            <div class="data">
                                <span>{{ 'separated.' + order.separated | translate }}</span>
                            </div>
                            <div class="data">
                                <span>{{ order.totalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="admin-table-container">
    <div class="title">
        <div class="tit">
            <h3>RENDELÉSEK</h3>
            <div class="add-icon">
                <button routerLink="/order">Összes rendelési tétel</button>
            </div>
        </div>
    </div>
    <div class="add-new-product">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="listFilter" type="search" placeholder="Rendelési azonosító" />
                <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div
        [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
        *ngFor="let order of filteredOrder; let i = index"
        class="admin-table"
    >
        <div class="field-container">
            <div class="field">
                <span>Rendelés dátuma</span>
                <div class="data">
                    <span>{{ order.orderDate | date }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Rendelési azonosító</span>
                <div class="data">
                    <span [ngStyle]="{ 'font-weight': 'bold' }">{{ order.orderReference }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Név</span>
                <div class="data">
                    <span>{{ order.name }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Email cím</span>
                <div class="data">
                    <span>{{ order.email }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Összevárja?</span>
                <div class="data">
                    <span>{{ 'separated.' + order.separated | translate }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Végösszeg</span>
                <div class="data">
                    <span>{{ order.totalPrice | currency : 'Ft' : 'symbol' : '1.0-0' }}</span>
                </div>
            </div>
        </div>
        <div class="field-container">
            <div class="field">
                <span>Rendelési tételek</span>
                <div class="data">
                    <button routerLink="{{ order.id }}">Rendelési tételek</button>
                </div>
            </div>
        </div>
    </div>
</div>
